<template>
  <div>
    <h1 class="text-size-h1 text-bold mb-s">{{ $metaInfo.title }}</h1>

    <p class="text-size-m mb-m">
      Список достижений ваших учеников за выбранный сезон НТО.
    </p>

    <div class="row mb-m">
      <div class="col-md-4 col-xs-12 col-sm-6">
        <BaseSelect
          v-model="season"
          :options="seasons"
          label="name"
          track-by="id"
          :allow-empty="false"
          placeholder="Выбрать сезон"
          preselect-first
          @select="handleSeasonChange"></BaseSelect>
      </div>
    </div>

    <div
      v-if="error"
      class="color-error">
      Не удалось получить данные. {{ error }}
    </div>
    <div
      v-else-if="pending"
      class="load">
      <BaseSpinner
        size="32px"
        brand>
      </BaseSpinner>
      <span>Получаем данные...</span>
    </div>
    <div v-else>
      <div
        v-for="item in sortedList"
        :key="item.idx"
        class="mb-m u-card">
        <div>
          <div class="u-card__title">
            {{ item.first_name }} {{ item.last_name }}
          </div>
          <div>
            Трек: {{ item.track_name
            }}<span v-if="item.education_title"
              >, {{ item.education_title }}</span
            >
          </div>

          <div class="u-card__data">
            <div class="u-card__achievements">
              <div
                v-for="achievement in item.achievements"
                :key="achievement.id"
                class="u-card__achievements-item">
                <div class="u-card__achievements-role">
                  {{ achievement.role_name }}
                </div>
                <div class="u-card__achievements-title">
                  {{ achievement.event_name }}
                </div>
              </div>
            </div>

            <div
              v-if="!item.achievements.length"
              class="color-meta">
              Нет достижений
            </div>
          </div>
        </div>
      </div>

      <div v-if="!list.length">
        <p>Нет данных по выбранному сезону</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllItems } from "@/services/utils/onti";
import { request, talentRequest } from "@/services/api";
const RESULT_CACHE_TTL = 1000 * 60 * 60 * 2;
const aggregateList = (
  students,
  achievements = {},
  events = {},
  tracks = {}
) => {
  return students.map((student) => {
    const aList = achievements[student.id] || [];
    return {
      id: student.id,
      first_name: student.first_name,
      last_name: student.last_name,
      education_title: student.education_title,
      avatar: student.avatar,
      season_id: student.season_id,
      track_id: student.track_id,
      talent_id: student.user?.talent_id,
      track_name:
        tracks[student.track_id]?.title || `Трек #${student.track_id}`,
      achievements: aList
        .map((n) => {
          return {
            ...n,
            role_name: n.role?.name || `Роль #${n.role?.id}`,
            event_name: events[n.event]?.title || `Мероприятие #${n.event}`,
          };
        })
        .sort((a, b) => {
          return b.id - a.id;
        }),
    };
  });
};

export default {
  name: "StudentsAchievements",
  metaInfo() {
    return {
      title: "Достижения учеников",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  data() {
    return {
      list: [],
      seasons: [],
      pending: true,
      season: null,
      error: "",
    };
  },

  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    sortedList() {
      const { list } = this;
      return list.sort((a, b) => {
        return b.achievements.length - a.achievements.length;
      });
    },
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      this.pending = true;
      // await this.$store.dispatch("getAchievementRoles");
      await this.$store.dispatch("getTracks");
      const seasons = await getAllItems({
        url: "/seasons",
        params: {
          page: 1,
          size: 40,
        },
      });

      this.seasons = seasons.sort((a, b) => b.id - a.id);
      if (this.seasons.length) {
        this.season = this.seasons[0];
        this.getList();
      }
    },
    handleSeasonChange(val) {
      if (!val) return;
      this.getList(val.id);
    },
    async getList() {
      const { season } = this;
      if (!season?.id) return;
      this.pending = true;
      this.error = "";

      const cacheKey = `${this.user.talent_id}_m_stat_${season.id}`;
      const saved = JSON.parse(localStorage.getItem(cacheKey));
      const tracks = this.$store.state.tracks;

      if (saved && Date.now() - saved.ts < RESULT_CACHE_TTL) {
        this.list = saved.data;
        this.pending = false;
        return;
      }

      this.list = [];
      try {
        const students = await getAllItems({
          url: "mentor/students",
          params: {
            page: 1,
            size: 40,
            season: season?.id,
          },
        });

        const requests = students.map((student) => {
          return request({
            method: "GET",
            url: `/mentor/students/${student.id}/achievements/${season?.talent_event}`,
          });
        });

        const eventIds = new Set();
        const achievements = (await Promise.all(requests)).reduce(
          (acc, req, idx) => {
            acc[students[idx].id] = req.data || [];
            req.data?.forEach((n) => eventIds.add(n.event));
            return acc;
          },
          {}
        );
        const { data: events } = await talentRequest({
          url: "/api/events/",
          params: {
            offset: 0,
            limit: eventIds.size,
            ids: [...eventIds].join(","),
          },
        });

        const eventsMap = events.results.reduce((acc, n) => {
          acc[n.id] = n;
          return acc;
        }, {});

        const result = aggregateList(students, achievements, eventsMap, tracks);
        localStorage.setItem(
          cacheKey,
          JSON.stringify({ ts: Date.now(), data: result })
        );
        this.list = result;
        this.pending = false;
      } catch (error) {
        this.error = error.message;
        this.pending = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.u-card {
  background-color: #fff;
  border-radius: @radius-xl;
  box-shadow: @shadow-default;
  padding: 16px 18px;

  &__title {
    font-weight: bold;
    font-size: 18px;
  }

  &__achievements {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    margin-top: 10px;

    &-item {
      flex: 1 0 30%;
    }

    &-title {
      font-size: 15px;
    }

    &-role {
      font-weight: bold;
      // color: @meta-color;
    }

    @media screen and (max-width: 960px) {
      display: block;

      &-item {
        margin-bottom: 10px;
        padding: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
      }
    }
  }
}

.load {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
